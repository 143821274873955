import Image from 'next/image'
import Link from 'next/link'
import logo from '@/public/images/logo.svg'

const NavBar = () => {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-white py-4 mb-4 mb-lg-5"
        style={{ backgroundColor: '#FFFFFF' }}
      >
        <div className="container px-5">
          <Link
            legacyBehavior
            className="navbar-brand"
            href="https://www.invoy.com"
          >
            <div>
              <Image src={logo} alt="Invoy logo" width="120" height="24" />
            </div>
          </Link>
          <h6 className="navbar-text d-none d-sm-inline-block p-0 m-0">
            <p className="mb-2" style={{ color: '#25265e' }}>
              Questions? Call (855) 615-0003
            </p>
            <span
              className="small fw-normal float-end"
              style={{ color: '#787993' }}
            >
              Monday-Friday: 8am-5pm PT
            </span>
            <br />
            <span
              className="small fw-normal float-end"
              style={{ color: '#787993' }}
            >
              Saturday: 9am-12pm PT
            </span>
            <br />
          </h6>
        </div>
      </nav>
      <div className="container mb-4 d-sm-none p-0">
        <h6 className="text-center">
          <p className="mb-4" style={{ color: '#25265e' }}>
            Questions? Call (855) 615-0003
          </p>
          <span className="small fw-normal" style={{ color: '#787993' }}>
            Monday-Friday: 8am-5pm PT
          </span>
          <br />
          <span className="small fw-normal" style={{ color: '#787993' }}>
            Saturday: 9am-12pm PT
          </span>
          <br />
        </h6>
      </div>
    </>
  )
}

export default NavBar
