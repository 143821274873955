import PropTypes from 'prop-types'

const AuthCard = ({ title, children }) => {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="card">
            <div className="card-body p-3">
              <h1 className="card-title mb-2">{title}</h1>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AuthCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default AuthCard
