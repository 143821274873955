import { useState } from 'react'
import { connect } from 'react-redux'
import Head from 'next/head'
import Link from 'next/link'
import { withRouter } from 'next/router'
import Layout, { siteTitle } from '@/components/layout'
import NavBar from '@/components/nav-bar'
import FormHandler from '@/components/form-handler'
import AuthCard from '@/components/auth-card'
import { loginUser, verifyCurrentUser } from '@/lib/redux/actions'
import {
  ACCESS_TOKEN_KEY,
  CURRENT_TENANT_ID,
  CURRENT_TENANT_SLUG,
} from '@/lib/constants'
import { storeKey, removeAuthKeys } from '@/lib/functions'

const DEFAULT_LOGIN_PATH = '/orientation'

function Login (props) {
  const {
    loginUser,
    verifyCurrentUser,
    handleSubmit,
    submitting,
    handleChange,
    values,
    router,
  } = props

  const { query } = router
  const [error, setError] = useState()
  const onSubmit = values => {
    let redirectPath = query.redirect || DEFAULT_LOGIN_PATH

    removeAuthKeys()
    setError('')

    return loginUser(values)
      .then(token => {
        storeKey(ACCESS_TOKEN_KEY, token)
        verifyCurrentUser().then(async user => {
          storeKey(CURRENT_TENANT_ID, user.tenantID)
          storeKey(CURRENT_TENANT_SLUG, user.tenantSlug)

          // A quick fix to handle conditional styling
          // do not use the router, this will force the styles to reload
          // Router.push(query.redirect || '/')
          window.location = redirectPath
        })
      })
      .catch(error => {
        setError(error.response.data.error)
      })
  }

  return (
    <Layout>
      <Head>
        <title>Login - {siteTitle}</title>
      </Head>
      <NavBar />

      <AuthCard title='Login'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-floating'>
            <input
              type='email'
              name='email'
              id='email'
              value={values.email || ''}
              onChange={handleChange}
              placeholder='Email'
              className='form-control'
              required
              autoFocus
            />
            <label htmlFor='email' className='mb-8'>
              Email
            </label>
          </div>
          <div className='form-floating'>
            <input
              type='password'
              name='password'
              id='password'
              value={values.password || ''}
              onChange={handleChange}
              placeholder='Password'
              className='form-control'
              required
            />
            <label htmlFor='password' className='mb-8'>
              Password
            </label>
          </div>
          <Link style={{ color: '#318ef6' }} href='/forgot-password'>
            <font className='form-text small d-block text-end mb-3'>
              Forgot your password?
            </font>
          </Link>
          {error && <p className='text-danger'>{error}</p>}
          <button
            type='submit'
            className='btn btn-primary btn-lg d-block w-100'
            disabled={submitting}
          >
            {submitting ? 'Logging you in...' : 'Log in'}
          </button>
        </form>
      </AuthCard>
    </Layout>
  )
}

function mapStateToProps (state) {
  const { currentUser } = state

  return {
    currentUser,
  }
}

const mapDispatchToProps = {
  loginUser,
  verifyCurrentUser,
}
const routed = withRouter(Login)
const form = FormHandler(routed)
const connected = connect(mapStateToProps, mapDispatchToProps)(form)

export default connected
